var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-layout',{attrs:{"wrap":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":""}},[(
          _vm.$route.path != '/RegisterPage' &&
          _vm.$route.path != '/login' &&
          _vm.$route.path != '/EmailPage' &&
          _vm.$route.path != '/login' &&
          _vm.$route.path != '/VerificationPage' &&
          _vm.$route.path != '*'
        )?_c('AppHeader'):_vm._e(),_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }