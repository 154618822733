import Vue from "vue";
import router from './router'
import axios from 'axios'
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({

    state: {
        isLoggedIn: !!localStorage.getItem("token"),
        userData: {},
    },
    mutations: {
        userData(state, item) {
            state.userData = item
        },
        logoutUser(state) {
            axios({
                method: "GET",
                url: "/media/user/logout",
                headers: {
                    "token": localStorage.getItem("token")
                }
            }).then(response => {
                if (response.data) {
                    state.isLoggedIn = false
                    state.userData = {}
                    state.initial = "";

                    localStorage.removeItem("token");
                    router.push('/')
                }
            })
        },
        loginUser(state, payload) {
            localStorage.setItem("token", payload);
            state.isLoggedIn = true

        },
    },
})