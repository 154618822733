import Vue from "vue";
import Router from "vue-router";
import store from './store';
Vue.use(Router);
let router = new Router({
    mode: "history",
    routes: [

        {
            path: '/',
            name: 'Front',
            component: () => import('./components/FrontPage'),


        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./components/LoginPage'),


        },
        {

            path: '/RegisterPage',
            name: 'RegisterPage',
            component: () => import("./components/RegisterPage"),
        },

        {

            path: '/VerificationPage',
            name: 'VerificationPage',
            component: () => import("./components/VerificationPage"),
        },

        {

            path: '/EmailPage',
            name: 'EmailPage',
            component: () => import("./components/EmailPage"),
        },
        {

            path: '/HomePage',
            name: 'HomePage',
            component: () => import("./components/HomePage"),
            meta: {
                requiresAuth: true,
              },
        },



        {

            path: '/Gallery',
            name: 'Gallery',
            component: () => import("./components/GalleryPage"),
            meta: {
                requiresAuth: true,
              },
        },


        // {

        //     path: '/Video',
        //     name: 'Home',
        //     component: () => import("./components/VideoPage"),
        // },

        {

            path: '/documents',
            name: 'documents',
            component: () => import("./components/DocumentsPage"),
            meta: {
                requiresAuth: true,
              },
        },

        {

          path: '/EventDetailPage',
          name: 'EventDetailPage',
          component: () => import("./components/EventDetailPage"),
          meta: {
              requiresAuth: true,
            },
      },


      {

        path: '/ProfileEditPage',
        name: 'ProfileEditPage',
        component: () => import("./components/ProfileEditPage"),
        meta: {
            requiresAuth: true,
          },
    },







        {
            path: '/videos',
            name: 'videos',
            component: () => import('./components/VideosPage'),
            meta: {
                requiresAuth: true,
              },
        },
            
        {
          path: '*',
          name: 'PageNotFound',
          props: true,
          component: () =>
              import ('./components/Common/checkValue'),
          meta: {
              requiresAuth: false,

          }
      },
  ],
  scrollBehavior() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
  },
});


router.beforeEach((to, from, next) => {
  
    if (
      to.matched.some((route) => route.meta.requiresAuth == true) &&
      store.state.isLoggedIn == false
    ) {
      next({ name: "Front", params: { lastPage: from } });
      return;
    }
    if (
      (to.name == "Login" || to.name == "Front" || to.name == "RegisterPage") &&
      store.state.isLoggedIn == true
    ) {
      next({ name: "HomePage" });
      return;
    }
    
    next();
  });

  export default router;