<template>
  <v-app>
    <!-- <v-app-bar style="background-color: black" v-if="$route.path != '/' ">
   
    </v-app-bar> -->
    <v-layout wrap fill-height>
      <v-flex xs12>
        <AppHeader
          v-if="
            $route.path != '/RegisterPage' &&
            $route.path != '/login' &&
            $route.path != '/EmailPage' &&
            $route.path != '/login' &&
            $route.path != '/VerificationPage' &&
            $route.path != '*'
          "
        />
        <router-view> </router-view>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import "./assets/styles/style.css";
import "./assets/styles/font.css";
import store from "./store";
import axios from "axios";
import AppHeader from "./components/Common/appHeader";
export default {
  components: {
    AppHeader,
  },

  beforeMount() {
    this.getname();
  },
  methods: {
    getname() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/media/user/me",

        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;

        if (response.data.status == true) {
          store.commit("userData", response.data.profile);
        } else {
          this.msg = response.data.msg;
        }
      });
    },
  },
};
</script>
