<template>
  <div>
    <v-app-bar
      v-if="
        $route.path == '/HomePage' ||
        $route.path == '/documents' ||
        $route.path == '/Gallery' ||
        $route.path == '/videos' ||
        $route.path == '/EventDetailPage' ||
        $route.path == '/ProfileEditPage'
      "
      class="hidden-sm-and-down grad"
      dense
      height="90px"
      dark
    >
      <v-layout wrap>
        <v-flex pt-7 xs5>
          <v-img
            contain
            src="../../assets/Images/icon.png"
            height="70%"
            width="70%"
          >
          </v-img
        ></v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex pl-6 pb-6 v-if="$route.path != '/'" text-right>
          <v-icon color="white" size="16"> mdi-account-circle-outline </v-icon>
          <span style="font-family: font2; color: white; font-size: 12px">
            {{ user.firstName }} 
          </span>

          <v-menu bottom offset-y transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-icon color="white" v-on="on">mdi-menu-down</v-icon>
            </template>

            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-card width="150px">
                  <v-layout wrap justify-center py-6>
                    <v-flex pt-4 pl-2 text-center>
                      <router-link
                        style="text-decoration: none"
                        to="/ProfileEditPage"
                      >
                        <v-btn
                          outlined
                          color="#7CB9E8"
                          class="poppinsBlack3"
                          small
                        >
                          <span
                            style="
                              font-family: font2;
                              color: black;
                              font-size: 12px;
                            "
                            >Edit Profile</span
                          >
                        </v-btn>
                      </router-link>
                    </v-flex>
                    <v-flex xs6 lg6 pt-2 sm6>
                      <v-btn
                        outlined
                        color="#7CB9E8"
                        class="poppinsBlack3"
                        small
                        @click="appLogout()"
                      >
                        <span
                          style="
                            font-family: font2;
                            color: black;
                            font-size: 12px;
                          "
                          >Logout</span
                        >
                      </v-btn>
                    </v-flex>

                
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-menu>
        </v-flex>
      </v-layout>
    </v-app-bar>

    <v-app-bar
      v-else-if="$route.path == '/'"
      class="hidden-sm-and-down grad"
      dense
      height="90px"
      dark
    >
      <v-layout wrap>
        <v-flex pt-7 xs5>
          <v-img
            contain
            src="../../assets/Images/icon.png"
            height="70%"
            width="70%"
          >
          </v-img
        ></v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs2 pt-2> </v-flex>
        <v-flex xs2 pt-2> </v-flex>
        <v-flex xs2 pt-2> </v-flex>
        <v-flex xs2 pl-4 pt-2 v-if="$route.path != '/HomePage'">
          <router-link style="text-decoration: none" to="/RegisterPage">
            <span
              class="c"
              style="
                font-family: font1;
                font-size: 15px;
                cursor: pointer;
                color: orange;
              "
              >Register</span
            >
          </router-link>
        </v-flex>
        <v-flex xs3 pt-2 v-if="$route.path != '/HomePage'">
          <router-link style="text-decoration: none" to="/Login">
            <span
              class="c"
              style="
                font-family: font1;
                font-size: 15px;
                cursor: pointer;
                color: orange;
              "
              >Media Log in</span
            ></router-link
          >
        </v-flex>
      </v-layout>
    </v-app-bar>

    <v-app-bar class="hidden-md-and-up grad">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      v-if="
        $route.path == '' ||
        $route.path == '/documents' ||
        $route.path == '/Gallery' ||
        $route.path == '/videos'
      "
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item> WELCOME {{ product.firstName }} </v-list-item>
          <v-list-item>
            <v-flex xs6 lg6 sm6>
              <v-btn
                outlined
                color="#000"
                class="poppinsBlack3"
                small
                @click="appLogout()"
              >
                <span style="color: #000">Logout</span>
              </v-btn>
            </v-flex>
          </v-list-item>

          <v-list-item> </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      v-else-if="$route.path == '/'"
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <router-link style="text-decoration: none" to="/RegisterPage">
            <v-list-item>
              <v-list-item-title class="font7">Register</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link style="text-decoration: none" to="/Login">
            <v-list-item>
              <v-list-item-title class="font7">Media Log in</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<style>
.c {
  text-transform: none;
}
</style>
<script>
import store from "./../../store";
export default {
  data() {
    return {
      ServerError: false,
      showSnackBar: false,
      product: [],
      list: "",
      msg: null,
      group: "",
      tab: "",
      appLoading: false,
      page: null,
      limit: null,
      drawer: "",
    };
  },

  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    user() {
      return store.state.userData;
    },
  },
  methods: {
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
  },
};
</script>
  <style>
.v-btn__content {
  color: antiquewhite;
}
</style>
