import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import store from './store';
import axios from 'axios';
import VueElementLoading from "vue-element-loading";
Vue.component("VueElementLoading", VueElementLoading);

// window.axios = require('axios')
//     axios.defaults.baseURL = 'http://192.168.51.187:5000';
//     Vue.prototype.baseURL = "http://192.168.51.187:5000"
//     Vue.prototype.mediaURL = "http://192.168.51.187:5000/u/"
// Vue.prototype.mediaURLnew = "http://192.168.51.187:5000/file/get/"


axios.defaults.baseURL = 'https://wtiadmin.in';
Vue.prototype.baseURL = "https://wtiadmin.in"
Vue.prototype.mediaURL = "https://wtiadmin.in/u/"
Vue.prototype.mediaURLnew = "https://wtiadmin.in/wp/"
Vue.prototype.mediaURLnews = "https://wtiadmin.in/file/get/"

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
